import api from "../utils/api";

export const fetchTrendingPost = async (prop) => {
    try {
        const response = await api.get('/trending/trending-post', {
            params: prop
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const fetchTrendingGroup = async (prop) => {
    try {
        const response = await api.get('/trending/topic-group', {
            params: prop
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const fetchTrendingMedia = async (prop) => {
    try {
        const response = await api.get('/trending/trending-media', {
            params: prop
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};