const Whatsapp = () => {
    return (
        <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="whatsapp logo">
                <g clip-path="url(#clip0_341_7217)">
                    <path id="Vector" d="M0.5 0H68.5V68H0.5" fill="#25D366" />
                    <path id="Vector_2" d="M17.791 50.833L20.1777 42.1125C18.151 38.5907 17.4665 34.4541 18.2506 30.4667C19.0348 26.4793 21.2345 22.9105 24.4437 20.4195C27.6528 17.9285 31.6544 16.6836 35.7095 16.9147C39.7645 17.1458 43.599 18.8374 46.5046 21.6769C49.4103 24.5164 51.1908 28.312 51.5173 32.3627C51.8438 36.4134 50.6942 40.4455 48.2809 43.7143C45.8676 46.983 42.3536 49.2675 38.388 50.1458C34.4224 51.0241 30.2731 50.4369 26.7065 48.4927M21.8308 46.8396L27.1244 45.4466C30.0376 47.3392 33.5509 48.0799 36.9803 47.5244C40.4096 46.9689 43.5095 45.1571 45.6763 42.4416C47.8431 39.7261 48.9218 36.3014 48.7022 32.8343C48.4827 29.3672 46.9807 26.1059 44.4887 23.6854C41.9967 21.2649 38.693 19.8585 35.221 19.74C31.749 19.6216 28.3571 20.7995 25.7059 23.0445C23.0547 25.2894 21.3338 28.4408 20.8784 31.8848C20.4231 35.3289 21.2658 38.8192 23.2424 41.676M31.8793 29.8815C32.0651 30.3459 31.8793 30.8102 30.8578 31.9433C30.3005 32.5005 30.4863 32.6862 31.4707 34.0793C32.4551 35.4723 34.0711 36.7725 35.557 37.469C37.0429 38.1655 36.95 38.1191 37.5723 37.3762C38.9653 35.7974 38.5938 35.4259 39.9869 36.0574L42.4944 37.2647C43.2373 37.6362 43.2745 37.6362 43.2838 38.1005C43.293 38.5649 43.1259 39.7722 42.6337 40.2923C42.1415 40.8123 40.3119 42.5954 37.0615 41.4067C33.811 40.218 31.5821 39.2707 27.8673 34.2557C24.1525 29.2407 27.7188 26.2689 28.2017 26.0832C28.6846 25.8974 28.8518 25.9439 29.7805 25.9624C30.0281 25.9624 30.2448 26.1172 30.4306 26.4268" fill="white" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_341_7217">
                    <rect x="0.5" width="68" height="68" rx="34" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default Whatsapp;