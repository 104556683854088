import React, { useState } from "react";
import { ArrowLeft, Attachment } from "iconoir-react";
import { PDFDocument } from "pdf-lib";

function MediaDialog({ uploadedFiles, setUploadedFiles, fileType, setFileType, onClose, setIsImage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [currentFileName, setCurrentFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setError(null)
      setSelectedFile(file);
      setCurrentFileName(file.name);
      
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      setError(null)
      setSelectedFile(file);
      setCurrentFileName(file.name);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      setError(null)
      setSelectedFile(file);
      setCurrentFileName(file.name);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("No file selected for upload.");
      return;
    }

    if(uploadedFiles?.length>=20){
      setError(`You can only upload 20 images`);
      return;
    }
  
    const file = selectedFile;
    const allowedImageFormats = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoFormats = ["video/mp4"];
    const allowedDocFormats = [
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
  
    // Check if uploaded files exist and validate type consistency
    if (uploadedFiles.length > 0) {
      const existingType = uploadedFiles[0].type.split("/")[0]; // e.g., 'image', 'video', etc.
      const newFileType = file.type.split("/")[0];
  
      if (existingType !== newFileType) {
        setError(`You can only upload files of the same type: ${existingType}.`);
        return;
      }
    }
  
    // Validation based on file type
    if (fileType && !isFileTypeAllowed(file)) {
      setError(`Only ${fileType.split("/")[0]} files are allowed.`);
      return;
    }
  
    if (allowedImageFormats.includes(file.type)) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      await new Promise((resolve) => (img.onload = resolve));
      const pixels = img.width * img.height;
      if (pixels > 36152320) {
        setError("Image exceeds maximum resolution of 36,152,320 pixels.");
        return;
      }
      if (file.size > 20 * 1024 * 1024) {
        setError("Image file size exceeds 20MB.");
        return;
      }
    } else if (allowedVideoFormats.includes(file.type)) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      await new Promise((resolve) => (video.onloadedmetadata = resolve));
      const duration = video.duration * 1000;
      if (duration < 3000 || duration > 30 * 60 * 1000) {
        setError("Video length must be between 3 seconds and 30 minutes.");
        return;
      }
      if (file.size > 500 * 1024 * 1024) {
        setError("Video file size exceeds 500MB.");
        return;
      }
    } else if (allowedDocFormats.includes(file.type)) {
      if (file.size > 100 * 1024 * 1024) {
        setError("Document size exceeds 100MB.");
        return;
      }
      if (file.type === "application/pdf") {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        const pdfDoc = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(PDFDocument.load(reader.result));
          reader.onerror = reject;
        });
        const numPages = pdfDoc.getPageCount();
        if (numPages > 300) {
          setError("PDF exceeds the maximum of 300 pages.");
          return;
        }
      }
    } else {
      setError("Unsupported file format.");
      return;
    }
  
    if (file.type.startsWith("image/")) {
      setIsImage(true);
    }
  
    // File is valid; proceed with upload
    setError(null);
    setIsLoading(true);
    setUploadProgress(0);
  
    try {
      for (let i = 0; i <= 100; i++) {
        await new Promise((resolve) => setTimeout(resolve, 10));
        setUploadProgress(i);
      }
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        { file, previewUrl: URL.createObjectURL(file), type: file.type },
      ]);
    } catch (err) {
      setError("Failed to upload files. Please try again.");
    } finally {
      setIsLoading(false);
      onClose(false);
    }
  };
  

  const isFileTypeAllowed = (file) => {
    const fileGroup = file.type.split("/")[0];
    const selectedGroup = fileType.split("/")[0];
    return fileGroup === selectedGroup;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: "rgba(106, 101, 167, 0.52)", backdropFilter: "blur(12px)" }}>
      <div className="relative p-8 w-[529px] bg-white rounded-[25px]">
        <div
          className="border-2 border-dashed border-star-light bg-star-light4 rounded-2xl p-2 mb-1 cursor-pointer h-[141px]"
          onClick={() => document.getElementById("fileInput")?.click()}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div className="flex flex-col items-center gap-1 h-[121px]">
            {isLoading ? (
              <>
                <div className="text-bodyThree text-star-light mt-5">Uploading {currentFileName}</div>
                <div className="w-full relative overflow-hidden rounded-full h-6 mt-11 border-2 border-star-light bg-star-light4">
                  <div className="bg-star-light2 h-full rounded-full" style={{ width: `${uploadProgress}%` }}></div>
                </div>
              </>
            ) : (
              <>
                <Attachment className="w-5 text-star-light mt-1" />
                <div className="text-bodyOne text-star-light">{currentFileName || "Upload media"}</div>
                <button className="text-bodyThree text-sm text-star-light hover:text-violet-700 mt-4">Drag & drop or browse</button>
                <p className="text-bodyThree text-sm text-star-light2">
                  {error ? <p className="text-red-500 mt-2 text-sm">{error}</p> : "Supports: Images, Videos AND PDFs"}
                </p>
              </>
            )}
          </div>
          <input type="file" id="fileInput" className="hidden" onChange={handleFileChange} accept="image/*,video/*,.pdf" />
        </div>

        <div className="flex justify-between mt-8">
          <button className="text-btn small" onClick={() => onClose(false)}>
            <ArrowLeft /> Back
          </button>
          <button
            onClick={handleUpload}
            disabled={isLoading || !selectedFile || error}
            className={`filled-btn small text-base ${isLoading || !selectedFile || error ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {isLoading ? "Uploading..." : "Upload File"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MediaDialog;
