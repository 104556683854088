export const MIXPANEL_EVENTS = {
    SIGNUP_CLICKED: 'signup_clicked',
    SIGNUP_VIEWED: 'signup_viewed',
    SIGNUP_SUCCESS: 'signup_success',
    SIGNUP_FAILURE: 'signup_failure',
    T_C_VIEWED: 't&c_viewed',
    LOGIN_CLICKED: 'login_clicked',
    LOGIN_VIEWED: 'login_viewed',
    OTP_SENT: 'otp_sent',
    OTP_RE_REQUESTED: 'otp_re-requested',
    OTP_SUBMITTED: 'otp_submitted',
    LOGIN_SUCCESS: 'login_success',
    LOGIN_FAILURE: 'login_failure',
    FORGOT_PW_CLICKED: 'forgot_pw_clicked',
    RESET_PW_CLICKED: 'reset_pw_clicked',
    RESET_PW_SENT: 'reset_pw_sent',
    RESET_RE_REQUESTED: 'reset_re-requested',
    SET_PW_CLICKED: 'set_pw_clicked',
    RESET_PW_SUCCESS: 'reset_pw_success',
    RESET_PW_FAILURE: 'reset_pw_failure',
    PERSONA_VIEW: 'persona_view',
    LINKEDIN_CONNECT_CLICKED: 'linkedin_connect_clicked',
    LINKEDIN_CONNECT_SUCCESS: 'linkedin_connect_success',
    LINKEDIN_CONNECT_FAILURE: 'linkedin_connect_failure',
    ONBOARDING_NEXT: 'onboarding_next',
    ONBOARDING_BACK: 'onboarding_back',
    LINKEDIN_SKIP: 'linkedin_skip',
    PRIVACY_VIEWED: 'privacy_viewed',
    ONBOARDING_DROPOFF: 'onboarding_dropoff',

    FILTER_CLICKED: 'filter_clicked',
    FILTER_LIKES: 'filter_likes',
    FILTER_TONE: 'filter_tone',
    FILTER_LENGTH: 'filter_length',
    FILTER_TIME: 'filter_time',
    SEARCH_KEYWORD: 'search_keyword',
    SUGGESTION_CLICKED: 'suggestion_clicked',
    FILTERS_REMOVED: 'filters_removed',
    NO_POSTS_FOUND: 'no_posts_found',
    POST_CLICK: 'post_click',
    COMMENTS_SELECTED: 'comments_selected',
    MANUALLY_CLICKED: 'manually_clicked',
    AI_CLICKED: 'ai_clicked',
    COMMENTS_CANCEL: 'comments_cancel',
    WRITING_BACK: 'writing_back',
    CONTENT_COPY: 'content_copy',
    PREVIEW_CLICKED: 'preview_clicked',
    PREVIEW_BACK: 'preview_back',
    SETTINGS_VIEWED: 'settings_viewed',
    RENEW_CLICKED: 'renew_clicked',
    INVOICE_VIEWED: 'invoice_viewed',
    CANCEL_REQUEST: 'cancel_request',
    LOGOUT_CLICKED: 'logout_clicked',
    SEE_MORE_CLICKED: 'see_more_clicked',
    RECREATE_CLICKED: 'recreate_clicked',

    // PAYMENT EVENTS
    PAYMENT_VIEWED: 'payment_viewed',
    PLAN_TOGGLED: 'plan_toggled',
    PAYMENT_INITIATED: 'payment_initiated',
    AMOUNT_RECEIVED: 'amount_received',
    PLAN_CHANGED: 'plan_changed',
    PAYMENT_FAILED: 'payment_failed',
    PAYMENT_RETRIED: 'payment_retried',

    // BOOKMARK EVENTS
    POST_BOOKMARKED: 'post_bookmarked',
    BOOKMARK_COLLECTION: 'bookmark_collection',
    BOOKMARKS_VISIT: 'bookmarks_visit',

    PLAN_RENEWED: 'plan_renewed',
    AI_EXPIRED: 'ai_expired',
    SEARCH_EXPIRED: 'search_expired',
    TRENDING_CLICKED: 'tranding_clicked',

    // Fav Creator
    ADD_CREATOR_CLICKED: 'add_creator',
    UPGRADE_CLICKED: 'upgrade_clicked',
    CREATOR_ADDED: 'creator_added',
    CREATOR_FAILED: 'creator_failed',
    SORT_CLICKED: 'sort_clicked',
    CREATOR_REMOVED: 'creator_removed',
    CREATOR_LIMIT: 'creator_limit',
    CREATOR_EMAIL: 'creator_email',

    TEMPLATE_TAB: 'template_tab',
    TEMPLATE_CATEGORY: 'template_category',
    TEMPLATE_FLIPPED: 'template_flipped',
    DEFAULT_TEMPLATE: 'default_template',
    TEMPLATE_COPIED: 'template_copied',
    TEMPLATE_RAW: 'template_raw',

    PREFERENCES_EDITED: 'preferences_edited',
    QUICK_PROMPT: "quick_prompt",
    VIRAL_PLATFORM: 'viral_platform',

    CREATOR_TAB: 'creator_tab',
    VIRAL_CONTENT_TAB: 'viral_content_tab',
    TOP_KEYWORD_TAB: 'top_keyword_tab',
    TRENDING_TAB_CLICKED:'trending_tab',
    // TRENDING_POST:'trending_tab_post',
    // TRENDING_IMAGES:'trending_tab',
};
