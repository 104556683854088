
const MasonryLayout = ({ children, columnCount = 3, gap = '16px' ,viralTemplate = false}) => {
    // Array to hold columns
    columnCount = viralTemplate ? 2 : columnCount
    const columns = Array.from({ length: columnCount }, () => []);

    // Distribute children into columns in order
    children?.forEach((child, index) => {
        columns[index % columnCount].push(child);
    });

    return (
        <div className="flex justify-between gap-4 flex-col lg:flex-row">
            {columns.map((column, colIndex) => (
                <div key={colIndex} className="flex-1 w-full flex flex-col" style={{ gap }}>
                    {column.map((child, childIndex) => (
                        <div key={childIndex} >
                            {child}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default MasonryLayout;
