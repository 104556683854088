import { useGoogleLogin } from '@react-oauth/google';
import GoogleImage from '../asset/svg/google.svg';
import { googleLogin } from '../services/auth.service';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../redux/slice/auth.slice';
import { MIXPANEL_EVENTS } from '../utils/mixpanel/eventType';
import { identifyUser, setSuperProperties, setUserProperties, trackEvent } from '../utils/mixpanel';

const trackSuccessLogin = (source, data) => {
    const { user, subscription, method } = data;
    identifyUser(user._id, {
        '$name': user.name,
        '$email': user.email,
        interests: user?.interest,
        persona: user?.occupation,
        linkedinConnected: user?.isLinkedinVerified,
    })
    setSuperProperties({
        // member_since: user?.createdAt,
        current_plan: subscription?.planName,
        // plan_renewal: subscription?.endDate,
        // persona: user?.occupation,
    })

    if (method === 'login') {
        trackEvent(MIXPANEL_EVENTS.LOGIN_SUCCESS, {
            "Login method": 'google',
        })
    } else {
        trackEvent(MIXPANEL_EVENTS.SIGNUP_SUCCESS, {
            "Sign-up method": 'google',
        })
        setUserProperties({
            "Signup source": "google"
        })
    }
}
const GoogleAuthButton = ({ source }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const result = await googleLogin({ accessToken: response.access_token });
                try {
                    dispatch(login(result));
                } catch (dispatchError) {
                    throw new Error('Sign-up was successful, but we encountered an issue saving your login information. Please log in again.');
                }
                trackSuccessLogin(source, result);
                navigate('/');
            } catch (error) {
                toast.error(error?.message || 'Something went wrong');
                if (source === 'login') {
                    trackEvent(MIXPANEL_EVENTS.LOGIN_FAILURE, {
                        "Reason (log in failure)": error?.message || 'Server Error'
                    })
                } else {
                    trackEvent(MIXPANEL_EVENTS.SIGNUP_FAILURE, {
                        "Reason (sign-up failure)": error?.message || 'Server Error',
                        "mode": "google",
                        // errorLog: JSON.stringify(error)
                    })
                }
            }
        },
        onError: (error) => {
            toast.error(error?.error_description || 'Something went wrong');
        },
    });

    return (
        <button onClick={handleLogin} className='bg-gray-moon border text-bodyTwo md:text-bodyOne text-space-dark flex justify-center items-center gap-2.5 px-[25px] py-[10px] rounded-3xl' style={{
            border: '1px solid transparent',
            backgroundImage: 'linear-gradient(#F7F6FF, #F7F6FF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
            outline: 'none'
        }}>
            <img src={GoogleImage} alt='google' />
            {source === 'signup' ? 'Sign up' : 'Sign in'} with Google
        </button>
    )
}

export default GoogleAuthButton;