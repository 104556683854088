const XIcon = () => {
    return (
        <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_341_7227)">
        <path d="M0.5 0H68.5V68H0.5" fill="#111111"/>
        <path d="M36.8329 32.2972L47.1536 20.3003H44.7079L35.7466 30.7173L28.5891 20.3003H20.3333L31.1566 36.0525L20.3333 48.6336H22.779L32.2424 37.6324L39.8017 48.6336H48.0575L36.8318 32.2972H36.8329ZM33.4828 36.1913L32.3863 34.6228L23.6602 22.1414H27.4172L34.4592 32.2139L35.5557 33.7824L44.709 46.8758H40.952L33.4828 36.1913Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_341_7227">
        <rect x="0.5" width="68" height="68" rx="34" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
    )
}

export default XIcon;