import { Download } from "iconoir-react";
import { Link as ReactLink, useLocation } from "react-router-dom";
import FullLogoWhite from "../../asset/svg/FullLogoWhite";
import Facebook from "../../asset/svg/social-share/Facebook";
import Link from "../../asset/svg/social-share/Link";
import Linkedin from "../../asset/svg/social-share/Linkedin";
import Whatsapp from "../../asset/svg/social-share/Whatsapp";
import XIcon from "../../asset/svg/social-share/XIcon";
import { unsecuredCopyToClipboard } from "../../component/text-editor/copyClipboard";
import { downloadImage } from "../../utils/helper";
import SignupScreen from "./SignupScreen";
import { useRef, useState } from "react";
import VideoPlayIcon from "../../asset/svg/VideoPlayIcon";
import { useSelector } from "react-redux";
import MetaTags from "../../component/MetaTags";
import { HelmetProvider } from 'react-helmet-async';

const LeftSide = ({ mediaType, mediaURL }) => {
    const shareURL = `${window.location.origin}/share?code=${mediaURL}&type=${mediaType}`

    const encodedImageUrl = encodeURIComponent(shareURL);

    const shareList = [
        {
            name: "LinkedIn",
            icon: <Linkedin />,
            href: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedImageUrl}`,
        },
        {
            name: "WhatsApp",
            href: `https://api.whatsapp.com/send?text=${encodedImageUrl}`,
            icon: <Whatsapp />

        },
        {
            name: "Facebook",
            href: `https://www.facebook.com/sharer/sharer.php?u=${encodedImageUrl}`,
            icon: <Facebook />
        },
        {
            name: "X",
            href: `https://twitter.com/intent/tweet?url=${encodedImageUrl}&text=Check out this image!`,
            icon: <XIcon />
        },
    ]

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const handleToggle = () => {
        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            videoRef.current.play();
            setIsPlaying(true);
        }
    }
    return (
        <div className="flex items-center justify-center gap-16 bg-star-light4 h-full">
            <div className="my-6 overflow-hidden max-w-[400px] rounded-lg group flex justify-center w-max  tooltip-wrapper ">
                {
                    mediaType === 'video' ? (
                        <div className="relative">
                            {
                                !isPlaying && (
                                    <div
                                        className="absolute rounded-lg top-0 h-full w-full  flex items-center justify-center"
                                    >
                                        <div className="h-full absolute bottom-0 left-0 w-full" style={{
                                            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.05%, rgba(0, 0, 0, 0.45) 99.77%)",
                                            opacity: 0.69
                                        }} />
                                        <div className="z-10">
                                            <VideoPlayIcon />
                                        </div>
                                    </div>
                                )
                            }

                            <video ref={videoRef} autoPlay onClick={handleToggle} src={mediaURL} className="rounded-lg object-contain  max-h-[80vh]" alt="media-video" />
                        </div>


                    ) : (
                        <img src={mediaURL} className="rounded-lg object-contain max-h-[80vh]" alt="" />
                    )
                }
                <div className="translate-y-6 opacity-0  transition-all group-hover:translate-y-0 group-hover:opacity-100 h-[30%] flex items-end absolute bottom-0 left-0 w-full" >
                    <div className="h-full absolute bottom-0 left-0 w-full" style={{
                        background: "linear-gradient(180deg, rgba(18, 11, 42, 0.00) 0%, #000 75.53%)",
                        opacity: 0.69
                    }} />

                    <div className="p-4 z-10 w-full">
                        <button
                            className="filled-btn mx-auto max-w-[250px] w-full small"
                            onClick={() => {
                                downloadImage(mediaURL)
                            }}
                        >
                            <Download />
                            Download
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 items-end justify-center">
                <p className="text-bodyOne text-gray-5">Share on</p>

                {
                    shareList.map((listItem) => (
                        <div className="flex flex-col gap-2 items-center justify-center">
                            <a href={listItem.href} target="_blank">
                                {listItem.icon}
                            </a>
                            <p className="text-bodyOne text-space-default">{listItem.name}</p>
                        </div>
                    ))
                }

                <div className="flex flex-col gap-2 items-center justify-center">
                    <button onClick={() => {
                        unsecuredCopyToClipboard(shareURL)
                    }}>
                        <Link />
                    </button>
                    <p className="text-bodyOne text-space-default">Link</p>
                </div>
            </div>
        </div>
    )
}



const MediaPreview = () => {
    const search = useLocation().search;
    const mediaURL = new URLSearchParams(search).get('code');
    const mediaType = new URLSearchParams(search).get('type');

    const authState = useSelector((state) => state.auth);

    const isLoggedIn = authState.isLoggedIn;

    const metaInfo = {
        title: `Preview ${mediaType || "Media"} - Superpen`,
        description: `Check out this trending ${mediaType || "media"} on Superpen!`,
        image: mediaURL,
        url: window.location.href,
    };
    return (
        <>
            <HelmetProvider>
                <MetaTags
                    title={metaInfo.title}
                    description={metaInfo.description}
                    image={metaInfo.image}
                    url={metaInfo.url}
                />
                <div className="h-screen">
                    <div className="bg-space-default px-2.5 py-2 flex items-center justify-center h-[44px]">
                        <div className="absolute left-3">
                            <FullLogoWhite />
                        </div>
                        {
                            !isLoggedIn && (
                                <div className="flex items-center justify-center py-2 px-2.5 text-center ">
                                    <p className="text-bodyTwoBold" style={{ color: '#F7F6FF' }}>Get unlimited trending images & posts on Superpen |</p>
                                    <ReactLink to={'/payment-pending'} className="ml-2 cursor-pointer text-bodyTwoBold text-btn-dark" style={{ padding: '4px 8px' }}><p>Start for Free</p></ReactLink>
                                </div>
                            )
                        }
                    </div>

                    <div className={`flex  h-[94vh]`}>
                        <div className={`h-full  ${isLoggedIn ? 'w-full' : 'w-[70%]'}`}>
                            <LeftSide mediaType={mediaType} mediaURL={mediaURL} />
                        </div>
                        {
                            !isLoggedIn && (
                                <div className=" w-[30%]">
                                    <SignupScreen />
                                </div>
                            )
                        }
                    </div>
                </div>
            </HelmetProvider>
        </>
    )
}




export default MediaPreview;