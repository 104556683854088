const Link = () => {
    return (
        <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="68" height="68" rx="34" fill="#EDEAFF" />
            <g clip-path="url(#clip0_341_7233)">
                <path d="M37.7831 33.2365L33.896 29.3494C31.683 27.1365 28.0951 27.1365 25.8821 29.3495L19.5267 35.7048C17.3137 37.9178 17.3137 41.5057 19.5267 43.7187L22.3535 46.5455C24.5665 48.7585 28.1544 48.7585 30.3674 46.5455L32.9084 44.0044" stroke="#A489F3" stroke-width="2.83333" stroke-linecap="round" />
                <path d="M29.9256 34.0003L33.8127 37.8874C36.0256 40.1003 39.6136 40.1003 41.8265 37.8874L48.1819 31.532C50.3949 29.319 50.3949 25.7311 48.1819 23.5181L45.3551 20.6913C43.1422 18.4784 39.5542 18.4784 37.3413 20.6913L34.8002 23.2324" stroke="#A489F3" stroke-width="2.83333" stroke-linecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_341_7233">
                    <rect width="45.3333" height="45.3333" fill="white" transform="translate(3.14545 27.3352) rotate(-33)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default Link;