const PostGroupIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M4.19495 14.6592V5.06784C4.19495 4.30078 4.81677 3.67896 5.58384 3.67896L14.3726 3.67896C15.1397 3.67896 15.7615 4.30078 15.7615 5.06784V14.6592C15.7615 15.4263 15.1397 16.0481 14.3726 16.0481H5.58384C4.81677 16.0481 4.19495 15.4263 4.19495 14.6592Z" stroke="currentColor" strokeWidth="1.04167" />
            <path d="M7.08643 7.42896H12.8697" stroke="currentColor" strokeWidth="1.04167" strokeLinecap="round" />
            <path d="M7.08643 10.0715H10.701" stroke="currentColor" strokeWidth="1.04167" strokeLinecap="round" />
            <path d="M4.23192 14.292L2.4299 14.292C1.66284 14.292 1.04102 13.6702 1.04102 12.9031L1.04101 2.43001C1.04101 1.41749 1.86183 0.596681 2.87435 0.59668L11.5569 0.59668C12.324 0.59668 12.9458 1.21851 12.9458 1.98557L12.9458 3.49211" stroke="currentColor" strokeWidth="1.04167" />
        </svg>
    )
}
export default PostGroupIcon;