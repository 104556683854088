export const isObjectEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  if (typeof obj !== 'object') {
    return false;
  }
  if (obj === null) {
    return true;
  }
  if (Object.keys(obj).length > 0) {
    return false;
  }
  return true;
}

export function nearestRoundOff(number) {
  if (number < 10) {
    return number;
  }
  const lastDigit = number % 10;

  return number - lastDigit;
}

export const convertToK = (num) => {
  if (num < 1000) {
    return num.toString(); // Return the number as is if less than 1000
  }

  const kValue = num / 1000;
  return kValue.toFixed(1) + 'k'; // Convert to k and keep 1 decimal point
};


export const verifyLinkedinTwitterURL = (url) => {
  if (!url) return null;

  let source, username;

  if (url.includes('linkedin.com')) {
    const match = url.match(/linkedin\.com\/in\/([^\/?]+)/);
    if (match) {
      source = 'linkedin';
      username = match[1];
    }
  } else if (url.includes('x.com')) {
    const match = url.match(/x\.com\/([^\/?]+)/);
    if (match) {
      source = 'twitter';
      username = match[1];
    }
  }

  return username ? { source, username } : null;
};


export async function downloadImage(imageSrc) {
  try {
    const response = await fetch(imageSrc, { mode: 'cors' });

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();
    const imageURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'image.png';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(imageURL);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading image:', error);
    // Fallback: Open the link in a new tab
    window.open(imageSrc, '_blank');
  }
}