const Facebook = () => {
    return (
        <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Facebook logo" clip-path="url(#clip0_341_7222)">
                <path id="Vector" d="M34.5001 68C53.2777 68 68.5001 52.7777 68.5001 34C68.5001 15.2223 53.2777 0 34.5001 0C15.7224 0 0.500061 15.2223 0.500061 34C0.500061 52.7777 15.7224 68 34.5001 68Z" fill="#3C5A9A" />
                <path id="Vector_2" d="M42.8365 18.1294H37.157C33.7867 18.1294 30.0379 19.5469 30.0379 24.4324C30.0543 26.1347 30.0379 27.765 30.0379 29.5998H26.1388V35.8043H30.1585V53.6662H37.5449V35.6865H42.4202L42.8614 29.5824H37.4177C37.4177 29.5824 37.4299 26.867 37.4177 26.0785C37.4177 24.1478 39.4266 24.2584 39.5474 24.2584C40.5034 24.2584 42.3621 24.2612 42.8392 24.2584V18.1294H42.8365Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_341_7222">
                    <rect width="68" height="68" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default Facebook;