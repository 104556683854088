const FullLogoWhite = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="102" height="24" viewBox="0 0 102 24" fill="none">
            <g clip-path="url(#clip0_499_6265)">
                <path d="M36.783 8.01577C36.5323 7.70741 36.2105 7.4623 35.8442 7.30077C35.4958 7.13259 35.1139 7.04208 34.7258 7.03571C34.537 7.03602 34.3487 7.05212 34.1627 7.08383C33.9809 7.11269 33.8064 7.17543 33.6485 7.2687C33.4967 7.35879 33.366 7.4793 33.2647 7.6224C33.1555 7.78651 33.1012 7.98015 33.1094 8.17616C33.1032 8.34501 33.1457 8.51214 33.2321 8.65817C33.3235 8.79662 33.4463 8.91223 33.591 8.99583C33.7696 9.10205 33.9584 9.19082 34.1545 9.26089C34.372 9.34108 34.6169 9.42465 34.8892 9.50991C35.2807 9.63822 35.6889 9.78004 36.1136 9.93536C36.528 10.0861 36.9184 10.2945 37.2728 10.5541C37.6226 10.8135 37.9166 11.1386 38.1378 11.5105C38.3802 11.9453 38.4988 12.4365 38.4809 12.9321C38.496 13.5177 38.37 14.0986 38.1134 14.6271C37.8804 15.09 37.5425 15.4941 37.1257 15.8081C36.7007 16.1246 36.2188 16.3592 35.7053 16.4995C35.1689 16.6494 34.6139 16.725 34.0563 16.724C33.2369 16.7254 32.4239 16.5812 31.6564 16.2985C30.9289 16.0436 30.2701 15.6287 29.73 15.0855L31.5586 13.2537C31.8652 13.6151 32.2465 13.908 32.6771 14.1131C33.1024 14.3345 33.5752 14.4531 34.0563 14.4592C34.2596 14.4594 34.4622 14.4376 34.6606 14.3942C34.8437 14.3569 35.0181 14.2861 35.1748 14.1856C35.3227 14.088 35.4434 13.9554 35.5256 13.7999C35.617 13.6207 35.662 13.422 35.6564 13.2216C35.6624 13.0271 35.6051 12.8358 35.493 12.6755C35.367 12.5098 35.2088 12.3704 35.0277 12.2652C34.7906 12.1262 34.5388 12.0133 34.2767 11.9284C33.9774 11.8263 33.6373 11.7162 33.2565 11.5983C32.8864 11.4808 32.5238 11.3416 32.1706 11.1813C31.8242 11.025 31.5049 10.8162 31.2241 10.5626C30.9413 10.3041 30.7138 9.99273 30.5547 9.64666C30.3749 9.23346 30.2885 8.78671 30.3017 8.33739C30.2851 7.77184 30.4201 7.21196 30.6932 6.71409C30.9464 6.27269 31.2982 5.8937 31.722 5.60573C32.1584 5.31107 32.645 5.09605 33.1587 4.97093C33.6867 4.83749 34.2296 4.76999 34.7747 4.77002C35.457 4.77527 36.1334 4.89488 36.7749 5.12372C37.4365 5.34884 38.0447 5.70411 38.5624 6.16792L36.783 8.01577Z" fill="#F6F3FF" />
                <path d="M45.5339 16.4353V15.3261H45.5013C45.3988 15.5144 45.2698 15.6875 45.1179 15.8401C44.9546 16.0055 44.7675 16.1463 44.5625 16.258C44.3403 16.3802 44.1048 16.4773 43.8605 16.5475C43.5981 16.6235 43.3259 16.6613 43.0525 16.6598C42.5757 16.6744 42.1024 16.5753 41.6728 16.3711C41.3153 16.1924 41.003 15.9372 40.759 15.624C40.5213 15.3122 40.3492 14.9569 40.2525 14.579C40.1492 14.1881 40.0971 13.7858 40.0977 13.382V8.43359H42.7913V12.7717C42.7906 12.9867 42.8069 13.2015 42.8402 13.4141C42.8686 13.6058 42.9322 13.7909 43.0281 13.9602C43.1149 14.1157 43.2416 14.2461 43.3956 14.3384C43.5804 14.4374 43.7892 14.4847 43.9994 14.4752C44.2075 14.486 44.4149 14.4444 44.6021 14.3543C44.7893 14.2641 44.95 14.1285 45.069 13.9602C45.3174 13.6068 45.4459 13.1852 45.4361 12.7556V8.43359H48.1135V16.4353H45.5339Z" fill="#F6F3FF" />
                <path d="M59.2474 12.4017C59.2492 12.9478 59.161 13.4906 58.9862 14.009C58.8225 14.5045 58.5649 14.9651 58.2271 15.3664C57.8927 15.7596 57.4784 16.0796 57.0109 16.3059C56.5023 16.5481 55.9431 16.6693 55.3782 16.6596C54.8886 16.662 54.4041 16.5607 53.9578 16.3625C53.5424 16.1885 53.1817 15.9084 52.9131 15.5512H52.8805V20.2911H50.2031V8.43338H52.7497V9.41343H52.7986C53.0816 9.06505 53.4346 8.77795 53.8356 8.57013C54.3149 8.31945 54.8519 8.19472 55.3945 8.20799C55.9469 8.2007 56.4938 8.31612 56.9946 8.54565C57.4619 8.76174 57.8767 9.07408 58.2108 9.46155C58.5489 9.85794 58.8091 10.3128 58.9781 10.8029C59.1585 11.3171 59.2496 11.8577 59.2474 12.4017ZM56.6519 12.4017C56.6518 12.1454 56.6104 11.8906 56.5293 11.647C56.4526 11.4097 56.3308 11.1888 56.1703 10.9962C55.9964 10.7911 55.7775 10.6274 55.53 10.5175C55.2826 10.4076 55.0131 10.3542 54.7418 10.3614C54.4593 10.3566 54.1796 10.417 53.9252 10.5378C53.6917 10.6515 53.4837 10.8099 53.3132 11.0038C53.1433 11.1985 53.0105 11.4219 52.9213 11.6631C52.736 12.15 52.736 12.6864 52.9213 13.1733C53.0103 13.4143 53.1431 13.6374 53.3132 13.8317C53.4832 14.0261 53.6914 14.1846 53.9252 14.2977C54.1794 14.4192 54.4592 14.4799 54.7418 14.4749C55.0295 14.481 55.3147 14.4202 55.5742 14.2977C55.805 14.1869 56.0085 14.0279 56.1703 13.8317C56.3312 13.6334 56.4529 13.4072 56.5293 13.1648C56.6101 12.9182 56.6515 12.6608 56.6519 12.4017Z" fill="#F6F3FF" />
                <path d="M68.9943 12.5301V12.8518C68.9945 12.9538 68.9891 13.0558 68.978 13.1573H63.0846C63.1034 13.3736 63.1732 13.5825 63.2883 13.7676C63.4042 13.9512 63.5537 14.1121 63.7292 14.2421C63.9124 14.3775 64.1164 14.4832 64.3334 14.5552C64.5568 14.6303 64.7913 14.6682 65.0273 14.6675C65.4005 14.679 65.7707 14.5989 66.1046 14.4345C66.3868 14.2889 66.6323 14.083 66.823 13.8318L68.6843 14.9891C68.3041 15.5345 67.7828 15.9699 67.1742 16.2503C66.4907 16.5567 65.7457 16.7076 64.9947 16.6918C64.4058 16.6952 63.8205 16.6001 63.2639 16.4107C62.7371 16.2335 62.2515 15.9549 61.8353 15.591C61.4206 15.223 61.0919 14.7709 60.8721 14.2657C60.6291 13.6964 60.5096 13.0834 60.5213 12.466C60.5124 11.8575 60.629 11.2536 60.864 10.6907C61.0772 10.183 61.3939 9.72359 61.7946 9.34095C62.1944 8.96639 62.6662 8.67422 63.1824 8.48161C63.7348 8.2746 64.3217 8.17097 64.9128 8.17604C65.4768 8.16997 66.0366 8.27085 66.5618 8.47318C67.0505 8.66376 67.4908 8.95737 67.8518 9.33337C68.2231 9.72795 68.509 10.1926 68.6924 10.6992C68.9025 11.2871 69.0047 11.907 68.9943 12.5301ZM66.5292 11.518C66.5376 11.1226 66.3948 10.7385 66.1291 10.4417C65.9749 10.2846 65.7875 10.1628 65.5801 10.0851C65.3727 10.0073 65.1504 9.97546 64.9291 9.99179C64.6843 9.98916 64.4409 10.0301 64.2108 10.1125C64.0019 10.1877 63.8084 10.2992 63.6395 10.4417C63.4781 10.5789 63.345 10.7454 63.2476 10.9322C63.1513 11.1137 63.0957 11.3135 63.0846 11.518L66.5292 11.518Z" fill="#F6F3FF" />
                <path d="M75.9325 10.6021C75.8176 10.5711 75.7002 10.5496 75.5817 10.538C75.4671 10.5279 75.3556 10.5219 75.2467 10.5219C74.9332 10.512 74.622 10.5781 74.3405 10.7144C74.1141 10.8278 73.9141 10.9862 73.753 11.1795C73.6092 11.3551 73.5011 11.5563 73.4348 11.7721C73.3747 11.9533 73.3416 12.1421 73.3366 12.3326V16.4352H70.6592V8.44281H73.2388V9.60014H73.2714C73.4724 9.19636 73.7762 8.8506 74.1531 8.59645C74.5423 8.33535 75.0043 8.19966 75.4753 8.20814C75.5842 8.20814 75.6901 8.21405 75.7939 8.22418C75.8797 8.23179 75.9645 8.24791 76.047 8.27229L75.9325 10.6021Z" fill="#F6F3FF" />
                <path d="M77.6143 5.05944H80.8796C81.4187 5.05372 81.9562 5.11585 82.4793 5.24432C82.9233 5.35151 83.342 5.54243 83.7122 5.80652C84.0513 6.05368 84.3232 6.37928 84.5039 6.7545C84.6976 7.17543 84.7924 7.6339 84.7814 8.09585C84.7926 8.55866 84.6949 9.01776 84.4958 9.4372C84.3103 9.81178 84.036 10.1369 83.6959 10.3852C83.3229 10.6513 82.9016 10.845 82.4548 10.9558C81.9298 11.0894 81.3893 11.1544 80.847 11.1491H78.7898V16.4352H77.6143V5.05944ZM78.7898 10.1682H80.7814C81.523 10.2178 82.2612 10.032 82.8875 9.6381C83.1263 9.45847 83.3167 9.22392 83.4419 8.95504C83.5672 8.68615 83.6235 8.39113 83.6059 8.09585C83.6293 7.79617 83.5732 7.49564 83.443 7.22376C83.3128 6.95189 83.1129 6.71803 82.8631 6.54514C82.233 6.18447 81.5091 6.0142 80.7814 6.05554H78.7898V10.1682Z" fill="#F6F3FF" />
                <path d="M92.7972 12.4497V12.6911C92.7968 12.7822 92.7913 12.8733 92.7809 12.9638H86.4465C86.455 13.3284 86.5326 13.6883 86.6751 14.0249C86.811 14.3491 87.0107 14.6438 87.2627 14.8918C87.516 15.1408 87.8152 15.34 88.1444 15.4785C88.5604 15.6473 89.0104 15.7192 89.4593 15.6886C89.9082 15.658 90.3438 15.5257 90.7322 15.3021C91.1157 15.0736 91.4421 14.7632 91.6872 14.3938L92.5196 15.037C92.1227 15.5569 91.6049 15.9755 91.0096 16.2577C90.4533 16.5055 89.849 16.6316 89.2384 16.6274C88.6984 16.6325 88.1627 16.5315 87.6628 16.3303C87.1931 16.142 86.768 15.8603 86.414 15.503C86.06 15.141 85.78 14.7154 85.5893 14.2494C85.3843 13.7493 85.2817 13.2143 85.2873 12.6751C85.2817 12.1363 85.3815 11.6016 85.5811 11.0999C85.7669 10.6335 86.0444 10.2078 86.3977 9.84719C86.7454 9.49356 87.1618 9.21225 87.6221 9.01992C88.1019 8.81902 88.6187 8.71758 89.1402 8.72193C89.6835 8.7114 90.223 8.81291 90.724 9.01992C91.1596 9.20274 91.5496 9.47667 91.8669 9.82271C92.1755 10.1671 92.4112 10.5686 92.5604 11.0037C92.7208 11.4693 92.8009 11.9581 92.7972 12.4497ZM91.671 12.0969C91.6613 11.4473 91.4151 10.8228 90.9771 10.3368C90.7403 10.0968 90.4535 9.90998 90.1364 9.78933C89.8194 9.66868 89.4795 9.617 89.1402 9.63785C88.7871 9.63481 88.437 9.70319 88.1118 9.83876C87.8024 9.9688 87.5201 10.154 87.279 10.3849C87.0423 10.6118 86.8488 10.8785 86.7077 11.1725C86.5675 11.4626 86.4844 11.7763 86.4629 12.0969H91.671Z" fill="#F6F3FF" />
                <path d="M96.1767 8.9153C96.1986 9.11874 96.2149 9.34357 96.2256 9.58978C96.2368 9.83627 96.2422 10.04 96.2419 10.2009H96.2745C96.5077 9.74541 96.8785 9.3721 97.3359 9.13225C97.8073 8.86356 98.3424 8.72204 98.8871 8.72199C99.2922 8.69262 99.6988 8.75401 100.076 8.90153C100.454 9.04906 100.792 9.27889 101.067 9.57374C101.575 10.2135 101.833 11.012 101.793 11.8234V16.435H100.683V12.2733C100.686 11.9123 100.653 11.5518 100.585 11.197C100.53 10.9019 100.416 10.6204 100.25 10.3689C100.09 10.1373 99.8705 9.95172 99.6136 9.83121C99.2981 9.69239 98.9546 9.62627 98.6092 9.6379C98.3142 9.63815 98.0225 9.69851 97.7524 9.81517C97.4674 9.93809 97.2137 10.1218 97.0096 10.3529C96.7761 10.6215 96.5986 10.9327 96.4872 11.2688C96.3482 11.6917 96.282 12.1345 96.2912 12.5789V16.435H95.1809V10.5698C95.1809 10.3664 95.1755 10.1042 95.1646 9.7831C95.1539 9.46148 95.1376 9.17222 95.1157 8.91532L96.1767 8.9153Z" fill="#F6F3FF" />
                <path d="M4.4961 0C4.35178 -1.16791e-06 4.20968 0.0350006 4.08237 0.10191C3.95506 0.168819 3.84646 0.265573 3.76619 0.383615L0.148395 5.7034C0.0519473 5.84523 0.000314029 6.01199 1.42786e-06 6.18265C-0.000311173 6.35331 0.0507109 6.52025 0.146638 6.66242L11.7802 23.9025C11.8086 23.9445 11.851 23.9755 11.9002 23.99C11.9493 24.0045 12.0021 24.0015 12.0493 23.9817C12.0965 23.9619 12.1351 23.9265 12.1585 23.8815C12.1819 23.8366 12.1886 23.785 12.1774 23.7357L8.93863 9.48575C8.91254 9.37097 8.91053 9.25216 8.93271 9.13659C8.9549 9.02102 9.00082 8.91112 9.06766 8.81363L13.5806 2.22967C13.6206 2.17129 13.6745 2.12342 13.7376 2.09022C13.8007 2.05701 13.8711 2.03949 13.9427 2.03917C14.0142 2.03885 14.0848 2.05574 14.1482 2.08837C14.2116 2.121 14.266 2.16839 14.3065 2.22641L18.8683 8.75199C18.937 8.85022 18.9843 8.96136 19.0072 9.07843C19.0301 9.19551 19.0281 9.31598 19.0015 9.43228L17.2687 16.9874C17.2574 17.0367 17.264 17.0883 17.2873 17.1332C17.3106 17.1782 17.3492 17.2137 17.3964 17.2336C17.4435 17.2535 17.4962 17.2565 17.5454 17.2421C17.5946 17.2278 17.6371 17.1969 17.6656 17.1549L24.7728 6.66276C24.8691 6.52055 24.9205 6.35342 24.9203 6.1825C24.9201 6.01158 24.8685 5.84454 24.7719 5.70249L21.1548 0.383611C21.0745 0.265574 20.9659 0.168823 20.8386 0.101915C20.7113 0.0350076 20.5692 4.0452e-06 20.4249 0L4.4961 0Z" fill="#F6F3FF" />
                <path d="M13.6546 8.44298L13.875 7.63745L14.0953 8.44298C14.1687 8.71124 14.3124 8.95582 14.512 9.15227C14.7116 9.34871 14.9601 9.49012 15.2327 9.56234L16.0512 9.77923L15.2327 9.99612C14.9601 10.0684 14.7116 10.2098 14.512 10.4062C14.3124 10.6026 14.1687 10.8472 14.0953 11.1155L13.875 11.921L13.6546 11.1155C13.5812 10.8472 13.4375 10.6026 13.2379 10.4062C13.0383 10.2098 12.7898 10.0684 12.5172 9.99612L11.6987 9.77923L12.5172 9.56234C12.7898 9.49012 13.0383 9.34871 13.2379 9.15227C13.4375 8.95583 13.5812 8.71124 13.6546 8.44298Z" fill="#F6F3FF" />
            </g>
            <defs>
                <clipPath id="clip0_499_6265">
                    <rect width="101.793" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default FullLogoWhite;